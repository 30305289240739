import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const NEW_MAIN_URL = process.env.REACT_APP_MAIN_URL;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const SITE_URL = "https://live.avertisystems.com";

const auth = axios.create({
  baseURL: BASE_URL,
});

// auth.interceptors.response.use((response) => response, (error) => {
//     if (error.response && error.response.data.message === 'Unauthenticated.') {
//         if (window.location.pathname !== '/login') {
//             window.location.pathname = '/login';
//         }
//     }
//     throw error;
// });

export async function get_all_stock() {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(`${SITE_URL}/api/v1/stock/all_get`, {
      headers: headers,
    });
    console.log("sssssssssssss", response);

    return response.data.finance.result;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function googleFinanceData() {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  try {
    const response = await axios.get(
      "https://live.avertisystems.com/api/v1/serpapi/GOOG:NASDAQ",
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "Application/json",
        },
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function googleFinanceMarketData(trend) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  try {
    const response = await axios.get(
      "https://live.avertisystems.com/api/v1/serpapi/trend/indexes",
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "Application/json",
        },
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export const stockgraphdata = async (range) => {
  try {
    const response = await axios.get(
      `https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-chart`,
      {
        params: {
          interval: "5m",
          symbol: "TSLA",
          range: range,
          region: "US",
        },
        headers: {
          "X-RapidAPI-Key":
            "d8eab7721cmshf7f21b0e88debffp10dcffjsnb5ffefe9fbfc",
          "X-RapidAPI-Host": "apidojo-yahoo-finance-v1.p.rapidapi.com",
        },
      },
    );
    // console.log("response",response)
    return response.data.chart.result;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
};

export async function indexy(Dataform) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/indexy/store`,
      Dataform,
      {
        headers: headers,
      },
    );
    console.log("sssssssssssss", response);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function boxie(client_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/indexy/get/${client_id}`,
      {
        headers: headers,
      },
    );

    console.log("Boxie response", response);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function updateindexy(id, upDataform) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/indexy/update/${id}`,
      upDataform,
      {
        headers: headers,
      },
    );
    console.log("updte", response);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function deleteboxie(id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.delete(
      `${SITE_URL}/api/v1/indexy/delete/${id}`,
      {
        headers: headers,
      },
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function folder(FolderData) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/indexy_folder/store`,
      FolderData,
      {
        headers: headers,
      },
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

export async function getfolder(client_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/indexy_folder/get/${client_id}`,
      {
        headers: headers,
      },
    );

    console.log("folder response", response);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function deletefolder(folderId) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.delete(
      `${SITE_URL}/api/v1/indexy_folder/delete/${folderId}`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function subfolder(SubFolderData) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/indexy_subfolder/post`,
      SubFolderData,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

///yha se

export async function getsubfolder(folder_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;
  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/indexy_subfolder/get/${folder_id}`,
      {
        headers: headers,
      },
    );

    console.log("Boxie response", response);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function deletesubfolder(sb_Id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.delete(
      `${SITE_URL}/api/v1/indexy_subfolder/delete/${sb_Id}`,
      {
        headers: headers,
      },
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function getsubfolderdata(Subfolder_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/indexy_subfolder/item/${Subfolder_id}`,
      {
        headers: headers,
      },
    );

    console.log("subfolderdata response", response.data);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function OutfitCrt(OutData) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;
  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/outfits/images`,
      OutData,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

export async function getoutfit(client_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/outfits/${client_id}`,
      {
        headers: headers,
      },
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function getsubfolderoutfit(Subfolder_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/indexy_subfolder/outfit/${Subfolder_id}`,
      {
        headers: headers,
      },
    );

    console.log("Boxie response", response.data);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function deleteoutfit(of_Id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.delete(`${SITE_URL}/api/v1/outfits/${of_Id}`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function updateoutfit(Out_id, updData) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/outfits/update/${Out_id}`,
      updData,
      {
        headers: headers,
      },
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

///neeww

export async function outfitCal(CalData) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  console.log("Request headers:", headers);

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/outfits/date`,
      CalData,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

export async function getcaloutfit(client_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/outfits/by-client/${client_id}`,
      {
        headers: headers,
      },
    );

    console.log("Boxie response", response.data);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function deletecaloutfit(of_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.delete(
      `${SITE_URL}/api/v1/outfits/deleteby-date/${of_id}`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function selfieCal(selData) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  console.log("Request headers:", headers);

  try {
    const response = await axios.post(
      `${SITE_URL}/api/v1/selfie/date`,
      selData,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

export async function getselfieCal(client_id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      `${SITE_URL}/api/v1/selfie/by-client/${client_id}`,
      {
        headers: headers,
      },
    );

    console.log("Boxie response", response.data);

    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function deletecalselfie(id) {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.delete(
      `${SITE_URL}/api/v1/selfie/deleteby-client/${id}`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export default auth;

/////

// export async function indexy(Dataform) { // Accept data as a parameter
//   const token = localStorage.getItem("token");
//   console.log("Token:", token);
//   if (token === null) return null;

//   // Define headers with Authorization
//   const headers = {
//     "Authorization": "Bearer " + token,
//     "Content-Type": "application/json", // Adjust Content-Type if necessary
//   };
//   console.log("Request headers:", headers);

//   try {
//     // Make the POST API call
//     const response = await axios.post(`https://live.avertisystems.com/api/v1/indexy/store`, Dataform, {
//       headers: headers,
//     });
//     // Return the relevant data from the API response
//     return response.data;
//   } catch (err) {
//     // Handle errors and throw appropriate messages
//     if (err.response) {
//       throw new Error(err.response.data.message);
//     } else {
//       throw new Error("An error occurred while posting data.");
//     }
//   }
// }

// export async function boxie() {
//   const token = localStorage.getItem("token");
//   if (token === null) return null;
//   headers["Authorization"] = "Bearer " + token;

//   try {
//     const response = await axios.get(`https://live.avertisystems.com/api/v1/indexy/get`, {
//       headers: headers,
//     });

//     console.log("Boxie response",response)

//     return response.data
//   } catch (err) {
//     if (err.response) {
//       throw new Error(err.response.data.message);
//     }
//   }
// }
