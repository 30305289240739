import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Login, GenerateOtp } from "../actions";
import History from "../History";
import Spinner from "./subcomponent/spinner/Spinner";
import Switchmode from "./Switchmode";
import { withAvertiLoader } from "../loaderHoc";

const customFontStyle = {
	fontFamily: "Georgia, serif",
	fontStyle: "oblique 23deg",
	color: "#3B5998",
	lineHeight: "1.8",
	fontWeight: "600",
	letterSpacing: "0.8px",
	textShadow: "2px 2px 2px rgba(0, 0, 0, 0.1)",
	// background:
	// 	"linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/assets/icons/docImg.avif')",
	// backgroundSize: "cover", // Ensures the image covers the entire element
	// backgroundPosition: "center", // Centers the image
};

const SpeechRecognition =
	window.SpeechRecognition || window.webkitSpeechRecognition;

class Signin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loginForm: {
				username: "",
				password: "",
			},
			loginError: {},
			phone: "",
			phoneError: "",
			enableBtn: false,
			listening: false,
			isPrompting: false,
			currentPrompt: null,
			styles: {
				voiceStatus: {
					position: "absolute",
					background: "rgba(0, 0, 0, 0.8)",
					color: "white",
					padding: "10px",
					borderRadius: "5px",
					marginTop: "10px",
					zIndex: 1000,
					right: "-100px",
					width: "200px",
					boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
				},
				voiceCommands: {
					listStyle: "none",
					paddingLeft: "0",
					margin: "5px 0 0",
					fontSize: "12px",
				},
				voiceCommandItem: {
					margin: "3px 0",
				},
			},
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.submit = this.submit.bind(this);
		this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
		this.submitPhone = this.submitPhone.bind(this);
		this.toggleListen = this.toggleListen.bind(this);
		this.speak = this.speak.bind(this);
		this.initializeVoiceCommands = this.initializeVoiceCommands.bind(this);
	}

	handleValidation() {
		let fields = this.state.loginForm;
		let errors = {};
		let formIsValid = true;

		if (!fields["username"]) {
			formIsValid = false;
			errors["username"] = "Please Enter the username/email.";
		}
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "Please Enter the password";
		}
		this.setState({ loginError: errors });
		return formIsValid;
	}

	onInputChange(event) {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			loginForm: {
				...prevState.loginForm,
				[name]: value,
			},
		}));
	}
	submit(event) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		if (this.handleValidation()) {
			this.props.Login(this.state.loginForm);
		}
	}

	handlePhoneNumber(event) {
		const value = event.target.value;
		this.setState({
			phone: value,
		});
	}

	submitPhone(event) {
		event.preventDefault();
		if (this.handlePhoneValidation()) {
			localStorage.setItem("phoneNumber", this.state.phone);
			let params = {
				mobile: this.state.phone,
			};
			this.props.GenerateOtp(params);
			History.push("/otp");
		}
	}

	handlePhoneValidation() {
		let errorsPhone = "";
		let formIsValid = true;

		if (this.state.phone === null && !this.state.phone) {
			formIsValid = false;
			errorsPhone = "Please Enter phone number.";
		}
		if (this.state.phone.length < 10 || this.state.phone.length > 12) {
			formIsValid = false;
			errorsPhone = "Please Enter valid phone number length.";
		}
		this.setState({ phoneError: errorsPhone });
		return formIsValid;
	}

	componentDidUpdate() {
		if (this.props.userLoader) {
			this.props.showLoader();
		} else {
			this.props.hideLoader();
		}

		if (this.props.user !== null) {
			History.push("/");
		}

		if (this.props.userDetail && this.props.userDetail.id !== "") {
			History.push("/");
		}
	}

	speak(text) {
		const utterance = new SpeechSynthesisUtterance(text);

		// Stop listening while speaking
		if (window.annyang && this.state.listening) {
			window.annyang.abort();
			this.setState({ listening: false }, () => {
				console.log("Listening paused for speaking.");
			});
		}

		utterance.onend = () => {
			// Resume listening after speaking
			if (window.annyang && !this.state.listening) {
				window.annyang.start({ autoRestart: true, continuous: true });
				this.setState({ listening: true }, () => {
					console.log("Listening resumed after speaking.");
				});
			}
		};

		window.speechSynthesis.speak(utterance);
	}

	initializeVoiceCommands() {
		if (window.annyang) {
			console.log("Configuring annyang...");

			window.annyang.removeCommands();

			const commands = {
				"clear email": () => {
					console.log("Clear email command recognized.");

					const emptyEmailEvent = {
						target: {
							name: "username",
							value: "",
						},
					};
					this.onInputChange(emptyEmailEvent);

					this.speak("Email cleared. Please say your email again.");
					this.setState({ emailSet: false, currentCommand: "email" });
				},
				"clear password": () => {
					console.log("Clear password command recognized.");

					const emptyPasswordEvent = {
						target: {
							name: "password",
							value: "",
						},
					};
					this.onInputChange(emptyPasswordEvent);

					this.speak("Password cleared. Please say your password again.");
					this.setState({ currentCommand: "password" });
				},
				"clear form": () => {
					console.log("Clear form command recognized.");

					const emptyEmailEvent = {
						target: {
							name: "username",
							value: "",
						},
					};
					const emptyPasswordEvent = {
						target: {
							name: "password",
							value: "",
						},
					};
					this.onInputChange(emptyEmailEvent);
					this.onInputChange(emptyPasswordEvent);

					this.speak("Form cleared. Please start over by saying your email.");
					this.setState({ emailSet: false, currentCommand: "email" });
				},
				clear: () => {
					console.log("Clear form command recognized.");

					const emptyEmailEvent = {
						target: {
							name: "username",
							value: "",
						},
					};
					const emptyPasswordEvent = {
						target: {
							name: "password",
							value: "",
						},
					};
					this.onInputChange(emptyEmailEvent);
					this.onInputChange(emptyPasswordEvent);

					this.speak("Form cleared. Please start over by saying your email.");
					this.setState({ emailSet: false, currentCommand: "email" });
				},
				login: () => {
					console.log("Login command recognized.");
					if (this.state.currentCommand === "loginReady") {
						this.speak("Submitting login form.");
						this.submit();
					} else {
						this.speak("Please enter your email and password first.");
					}
				},
				"*input": (input) => {
					console.log("Wildcard command recognized. Received input:", input);

					if (this.state.currentCommand === "email") {
						// Format email input
						const formattedEmail = input
							.toLowerCase()
							.replace(/ at /g, "@")
							.replace(/ dot /g, ".")
							.replace(/\s+/g, "");

						console.log("Formatted email:", formattedEmail);

						// Simulate an input change event for email
						const event = {
							target: {
								name: "username",
								value: formattedEmail,
							},
						};

						this.onInputChange(event);
						this.setState({ emailSet: true, currentCommand: "password" });
						this.speak("Email entered. Please say your password now.");
					} else if (this.state.currentCommand === "password") {
						console.log("Received password:", input);

						const event = {
							target: {
								name: "password",
								value: input,
							},
						};

						this.onInputChange(event);
						this.setState({ currentCommand: "loginReady" });
						this.speak(
							"Password entered. Say login to submit the form or clear to start over.",
						);
					} else {
						// this.speak(
						// 	"I am not sure what to do. Please start over by saying your email.",
						// );
					}
				},
			};

			window.annyang.addCommands(commands);

			window.annyang.debug(true);
		}
	}

	toggleListen() {
		if (window.annyang) {
			if (!this.state.listening) {
				window.annyang.start({
					autoRestart: true,
					continuous: true,
					paused: false,
				});
				this.speak(
					"Voice recognition activated. Please say your email address.",
				);

				this.setState({ listening: true, currentCommand: "email" }, () => {
					console.log("Listening state updated:", this.state.listening);
				});
			} else {
				window.annyang.abort();
				window.speechSynthesis.cancel();

				this.setState({ listening: false, currentCommand: null }, () => {
					console.log("Listening state updated:", this.state.listening);
				});
			}
		} else {
			console.error("Annyang is not available");
		}
	}

	componentDidMount() {
		const script = document.createElement("script");
		script.src =
			"https://cdnjs.cloudflare.com/ajax/libs/annyang/2.6.0/annyang.min.js";
		script.async = true;

		script.onload = () => {
			console.log("Annyang script loaded");
			this.initializeVoiceCommands();
			if (window.annyang) {
				window.annyang.setLanguage("en-US");
				window.annyang.debug(true);
			}
		};

		script.onerror = () => {
			console.error("Failed to load annyang script");
		};

		document.body.appendChild(script);
	}

	componentWillUnmount() {
		if (window.annyang) {
			window.annyang.abort();
		}
		window.speechSynthesis.cancel();
	}

	render() {
		return (
			<React.Fragment>
				{this.props.loaderDiv}
				{/* <div
					className="header-area"
					style={{ backgroundImage: "url(assets/img/bg/1.png)" }}
				>
					<div className="container">
						<div className="row">
							<div className="col-sm-4 col-3"></div>
							<div className="col-sm-4 col-6 text-center">
								<div className="page-name font-bold">Login</div>
							</div>
							<div className="col-sm-4 col-3 text-right"></div>
						</div>
					</div>
				</div> */}
				<div className="container mt-2" style={customFontStyle}>
					<div className=" ">
						<div className="container">
							<div className="d-flex align-items-center justify-content-end">
								<div className="me-2">
									<Switchmode />
								</div>
								<Link id="header-user" className="header-user" to={"/"}>
									<img
										src={process.env.PUBLIC_URL + "/assets/img/flag1.png"}
										alt="img"
										width={50}
									/>
								</Link>
							</div>

							<div className="d-flex justify-content-between">
								<h6
									className="header-content py-0 my-0"
									style={{
										fontSize: "28px",
										color: "blue",
										fontWeight: "700",
									}}
								>
									Welcome,
									<br />
									<span
										className="mt-2"
										id="dark_span"
										style={{
											fontSize: "22px",
											color: "#7851A9",
											fontWeight: "500",
											display: "block",
											marginTop: "10px",
										}}
									>
										To your Life Management account
									</span>
									<br />
									<div
										className="edit-icon margin align-items-center"
										style={{
											display: "flex",
											alignItems: "center",
											marginTop: "20px",
										}}
									>
										<span
											className="mb-1"
											id="dark_span"
											style={{
												fontSize: "18px",
												color: "#f93910",
												fontWeight: "400",
												marginRight: "15px",
											}}
										>
											Click here for voice assistant
										</span>
										<img
											src={process.env.PUBLIC_URL + "/assets/img/google.png"}
											alt="microphone"
											onClick={this.toggleListen}
											style={{
												cursor: "pointer",
												width: "50px",
												height: "50px",
											}}
										/>
									</div>
								</h6>
							</div>
						</div>
					</div>
					<div className="widget-body sliding-tabs">
						<ul
							className="nav nav-tabs widget has-shadow px-0"
							id="example-one"
							role="tablist"
						>
							<li className="nav-item w-50">
								<a
									className="nav-link active"
									id="base-tab-1"
									data-toggle="tab"
									href="#tab-1"
									role="tab"
									aria-controls="tab-1"
									aria-selected="true"
								>
									<button type="button" className="btn-c btn-secondary w-100">
										Email
									</button>
								</a>
							</li>
							<li className="nav-item w-50">
								<a
									className="nav-link"
									id="base-tab-2"
									data-toggle="tab"
									href="#tab-2"
									role="tab"
									aria-controls="tab-2"
									aria-selected="false"
								>
									<button type="button" className="btn-c btn-secondary w-100">
										Phone Number
									</button>
								</a>
							</li>
						</ul>
						<div className="tab-content pt-3">
							{this.props.error ? (
								<div
									className="alert alert-danger alert-dissmissible fade show"
									role="alert"
								>
									<strong>Alert!</strong> {this.props.errorMessage}
								</div>
							) : null}

							<div
								className="tab-pane fade show active"
								id="tab-1"
								role="tabpanel"
								aria-labelledby="base-tab-1"
							>
								<div className="signin-area p-4">
									<div className="container px-0">
										<form
											className="contact-form-inner px-0"
											onSubmit={this.submit}
										>
											<label className="single-input-wrap">
												<span id="dark_span">Email*</span>
												<input
													type="text"
													name="username"
													value={this.state.loginForm.username}
													onChange={this.onInputChange}
												/>
												<small className="btn-danger">
													{this.state.loginError["username"]}
												</small>
											</label>
											<label className="single-input-wrap">
												<span id="dark_span">Password*</span>
												<input
													type="password"
													name="password"
													value={this.state.loginForm["password"]}
													onChange={this.onInputChange}
												/>
												<small className="btn-danger">
													{this.state.loginError["password"]}
												</small>
											</label>
											<div className="single-checkbox-wrap">
												<label htmlFor="remember">
													<input
														id="remember"
														type="checkbox"
														name="remember"
														value="1"
													/>{" "}
													<span id="dark_span">Remember password</span>
												</label>
											</div>
											<button type="submit" className="btn-c btn-success">
												Login{" "}
												{this.props.loader ? <Spinner size="mini" /> : null}
											</button>
										</form>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="tab-2"
								role="tabpanel"
								aria-labelledby="base-tab-2"
							>
								<div className="signin-area">
									<div className="container px-0">
										<form
											className="contact-form-inner px-0"
											onSubmit={this.submitPhone}
										>
											<label className="single-input-wrap">
												<span id="dark_span">Phone*</span>
												<span className="phone-login">
													<input
														type="text"
														id="phone"
														placeholder="e.g. 702 123 4567"
														name="phone"
														value={this.state.phone}
														onChange={this.handlePhoneNumber}
													/>
													<small className="btn-danger">
														{this.state.phoneError}
													</small>
												</span>
											</label>
											{/* <Link to={'/otp'}><button type="button" className="btn-c btn-success mr-1 mb-2" >Request OTP</button></Link> */}
											<button type="submit" className="btn-c btn-success">
												Request OTP
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		error: state.auth.error,
		errorMessage: state.auth.errorMessage,
		loader: state.auth.loader,
		user: state.auth.userDetail,
		userDetail: state.user.userDetail,
		userLoader: state.user.loader,
	};
};

export default connect(mapStateToProps, { Login, GenerateOtp })(
	withAvertiLoader(Signin),
);
