import React, { useState, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import Section from "./layouts/Section";
import "../darkmode.css";
import Footer from "./layouts/Footer";

const VideoAi = () => {
	const history = useHistory();
	const [videoUrl, setVideoUrl] = useState("");

	const iframeRef = useRef(null);

	const toggleFullscreen = () => {
		if (iframeRef.current.requestFullscreen) {
			iframeRef.current.requestFullscreen();
		}
	};

	const handleLinkClick = (url) => {
		setVideoUrl(url);
	};

	const handleBackClick = () => {
		if (videoUrl) {
			setVideoUrl("");
		} else {
			history.goBack();
		}
	};

	return (
		<div className="mobile-only">
			{/* <Section allNotification={false} title={"AI Assistant"}> */}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					padding: "10px",
					cursor: "pointer",
					color: "#007BFF",
				}}
			>
				<ArrowBackIcon
					style={{
						fontSize: 30,
						color: "#007BFF",
					}}
					onClick={handleBackClick}
				/>
				<h2
					style={{
						marginLeft: "10px",
						color: "#007DFF",
					}}
				>
					AI Assistant
				</h2>
			</div>
			{!videoUrl && (
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: "20px",
					}}
				>
					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/youtube2")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<div
							className="text-center"
							style={{
								boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
								padding: "15px 0px",
								margin: 10,
							}}
						>
							<img
								src="./avatar/Anna.webp"
								style={{
									width: "120px",
									height: "120px",
									objectFit: "cover",
								}}
								alt="Youtube"
							/>
							<h4 className="ta-center mt-2">Youtube</h4>
						</div>
					</div>

					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/weather")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<div
							className="text-center"
							style={{
								boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
								padding: "15px 0px",
								margin: 10,
							}}
						>
							<img
								src="./avatar/Edward.webp"
								style={{
									width: "120px",
									height: "120px",
									objectFit: "cover",
								}}
								alt="Weather"
							/>
							<h4 className="ta-center mt-2">Weather</h4>
						</div>
					</div>

					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/currency")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<div
							className="text-center"
							style={{
								boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
								padding: "15px 0px",
								margin: 10,
							}}
						>
							<img
								src="./avatar/Wade.webp"
								style={{
									width: "120px",
									height: "120px",
									objectFit: "cover",
								}}
								alt="Currency"
							/>
							<h4 className="ta-center mt-2">Currency</h4>
						</div>
					</div>

					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/hotels")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<div
							className="text-center"
							style={{
								boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
								padding: "15px 0px",
								margin: 10,
							}}
						>
							<img
								src="./avatar/Leah.webp"
								style={{
									width: "120px",
									height: "120px",
									objectFit: "cover",
								}}
								alt="Hotel"
							/>
							<h4 className="ta-center mt-2">Hotel</h4>
						</div>
					</div>

					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/news")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<div
							className="text-center"
							style={{
								boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
								padding: "15px 0px",
								margin: 10,
							}}
						>
							<img
								src="./avatar/Kayla.webp"
								style={{
									width: "120px",
									height: "120px",
									objectFit: "cover",
								}}
								alt="News"
							/>
							<h4 className="ta-center mt-2">News</h4>
						</div>
					</div>

					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/perAssis")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
							height: "180px",
							boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
							padding: "15px 0px",
							margin: 10,
						}}
					>
						<img
							src="./avatar/Justin.webp"
							style={{
								width: "120px",
								height: "100px",
								objectFit: "cover",
							}}
							alt="Personal Assistant"
						/>
						<h4 className="ta-center mt-2">
							Personal <br /> Assistant
						</h4>
					</div>

					<div
						onClick={() =>
							handleLinkClick("https://aiassist.avertisystems.com/clientNav")
						}
						style={{
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
							height: "180px",
							boxShadow: "rgb(239, 210, 149, 0.8) 0px 4px 10px",
							padding: "15px 0px",
							margin: 10,
						}}
					>
						<img
							src="./avatar/Leah.webp"
							style={{
								width: "120px",
								height: "100px",
								objectFit: "cover",
							}}
							alt="Website Assistant"
						/>
						<h4 className="ta-center mt-2">
							Website <br /> Assistant
						</h4>
					</div>
				</div>
			)}

			{videoUrl && (
				<div
					id="client_section_ai"
					style={{ marginTop: 2, textAlign: "center" }}
				>
					<iframe
						ref={iframeRef}
						style={{ height: "100vh" }}
						width="560"
						src={videoUrl}
						title="AI Assistant Video"
						frameBorder="2px"
						allow="accelerometer; autoplay; clipboard-write; gyroscope; microphone; speech-recognition; picture-in-picture"
						allowFullScreen
					></iframe>
					{/* <button onClick={toggleFullscreen}>Fullscreen</button> */}
				</div>
			)}
			<Footer />
		</div>
	);
};

export default VideoAi;
