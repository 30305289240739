import React from "react";
//import "./switchmode.css";
import { connect } from "react-redux";
import { toggleTheme } from "../actions/index";

const ThemeSwitch = (props) => {
	const handleToggle = (e) => {
		const checked = e.target.checked;
		const t = document.querySelector("body");
		if (checked) {
			t.classList.add("dark-theme");
			t.classList.remove("light-theme");
		} else {
			t.classList.add("light-theme");
			t.classList.remove("dark-theme");
		}

		const theme = checked === true ? 1 : 0;
		props.toggleTheme(theme);
	};

	const { darkTheme } = props;

	return (
		<div className={"switch-container p-4"}>
			<label className="switch-label">
				<input
					type="checkbox"
					checked={darkTheme ? true : false}
					onChange={handleToggle}
					style={{ display: "none" }}
				/>
				<div className="switch-slider"></div>
				<span className="switch-mode">
					{darkTheme ? (
						<img
							src={process.env.PUBLIC_URL + "/assets/icons/dark.png"}
							alt="Moon"
							className="theme-icon"
							style={{ width: "35px", height: "35px" }}
						/>
					) : (
						<img
							src={process.env.PUBLIC_URL + "/assets/icons/light.png"}
							alt="Sun"
							className="theme-icon"
							style={{ width: "35px", height: "35px" }}
						/>
					)}
					{/* {darkTheme ? " Dark" : " Light"} Mode */}
				</span>
			</label>
		</div>
	);
};

const mapStateToProps = (state) => ({
	darkTheme: state.user.darkTheme,
});

const actionDispatcher = {
	toggleTheme,
};

export default connect(mapStateToProps, actionDispatcher)(ThemeSwitch);
