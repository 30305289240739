import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import './main.css';
import '../darkmode.css';
import Homeicon from '../icons/home.png';
import Callicon from '../icons/phone.png';
import Chaticon from '../icons/chat.png';
import Billsicon from '../icons/bills.png';
import Filesicon from '../icons/files.png';
import Healthicon from '../icons/health.png';
import Stocksicon from '../icons/stocks.png';
import Reporticon from '../icons/report.png';
import settingIcon from '../icons/settings.png';
import ZoomIcon from '../icons/zoom.webp';
import Galleryicon from '../icons/gallery.png';
import Taskicons from '../icons/task.png';
import TrainingIcon from '../icons/training.png';
import Notificationicons from '../icons/notification.jpg';
import FooterModal from './layouts/Footer-Modal';
import { connect } from "react-redux";
import { getChats, GetProfile } from '../actions';
import CalendarButton from './calendar-button';
import SwitchTheme from "./Switchmode";
import Outfiticon3 from '../icons//Outfiticon3.jpg';
import chatbot from "../icons/chatbot.png";


function Mainpage(props) {

    const { userDetail, GetProfile, getChats } = props;

    useEffect(() => {
        if (userDetail.id === '')
            GetProfile()
        getChats()

        return () => { }
    }, [GetProfile, getChats])


    return (
        <>
            <div className="mainpage">
                <div className="mainpagehead">
                    <div className="container mainpagecontain">
                        <div className="row mainpagerow">
                            <ul className='mainpageul' style={{ marginBottom: "50px" }}>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/home'}>
                                        <img loading='lazy' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }} src={Homeicon} alt={Homeicon} className="homeIcon" />
                                        <h6 className='ta-center mt-2'>Home</h6>
                                    </Link>
                                </li>

                                <li className='mainpageli'>
                                    <CalendarButton />
                                </li>

                                <li className='mainpageli'>
                                    <div className="ba-add-balance-btnhome-clicked __callbtn" data-toggle="modal" data-target="#__footer-madal">
                                        <img style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }} loading='lazy' src={Callicon} alt={Callicon} className="callIcon" />
                                        <h6 className='ta-center mt-2'>Call</h6>
                                        <FooterModal />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="row mainpagerow">
                            <ul className='mainpageul' style={{ marginBottom: "50px" }}>

                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/chats'}>
                                        <img loading='lazy' src={Chaticon} alt={Chaticon} className="chatIcon" />
                                        <h6 className='ta-center mt-2'>Chat</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/report-page'}>
                                        <img loading='lazy' src={Reporticon} alt={Reporticon} className="reportIcon" />
                                        <h6 className='ta-center mt-2'>Reports</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/document'}>
                                        <img loading='lazy' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }} src={Filesicon} alt={Filesicon} className="filesIcon" />
                                        <h6 className='ta-center mt-2'>Document</h6>
                                    </Link>
                                </li>
                            </ul>
                        </div>


                        <div className="row mainpagerow">
                            <ul className='mainpageul' style={{ marginBottom: "50px" }}>

                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/task'}>
                                        <img loading='lazy' src={Taskicons} alt={Taskicons} className="taskIcon" />
                                        <h6 className='ta-center mt-2'>Task</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/outfits-section'}>
                                        <img loading='lazy' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }} src={Outfiticon3} alt={Outfiticon3} className="zoomIcon" />
                                        <h6 className='ta-center mt-2'>Outfits</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/zoom'}>
                                        <img loading='lazy' src={ZoomIcon} alt={ZoomIcon} className="zoomIcon" />
                                        <h6 className='ta-center mt-2'>Meetings</h6>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="row mainpagerow">
							<ul className="mainpageul" style={{ marginBottom: "50px" }}>
								<li className="mainpageli">
									<Link className="ba-add-balance-btn" to={"/videoai"}>
										<img
											loading="lazy"
											src={chatbot}
											alt={chatbot}
											className="taskIcon"
										/>
										<h6 className="ta-center mt-2">AI Avatar</h6>
									</Link>
								</li>
							</ul>
						</div>

                        <div className='row mb-auto'>
                            <div className='col-md-12 d-flex align-items-center justify-content-center'>
                                <SwitchTheme />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        client_secret: state.payment.clientSecret,
        loader: state.payment.loader,
        userDetail: state.user.userDetail,
        chats: state.chat.chats,
        events: state.event.events
    }
}

export default connect(mapStateToProps, { getChats, GetProfile })(Mainpage);
