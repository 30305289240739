import auth from "../api/auth";
import axios from "axios";
import {
  LOGIN_ERROR,
  PROFILE_ERROR,
  PROFILE_FETCH,
  SIGN_IN,
  SIGN_OUT,
  USER_PROFILE,
  PROFILE_UPDATE,
  LOADER_PROFILE,
  LOADER_AUTH,
  OTP,
  OTP_ERROR,
  PASSWORD_CHANGE,
  PASSWORD_CHANGE_ERROR,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_ERROR,
  TOGGLE_THEME,
} from "../store/redux-constant";

const loginURL = "/login";
const mainURL = "/";

const headers = {
  "Content-Type": "application/json",
};

export * from "./payment";
export * from "./chat";
export * from "./event";
// Action creator
export const userProfile = (user) => {
  return {
    type: USER_PROFILE,
    payload: user,
  };
};

export const loginError = () => {
  return {
    type: LOGIN_ERROR,
  };
};

export const otpError = (error) => {
  return {
    type: OTP_ERROR,
    payload: error,
  };
};

const goToLogin = () => {
  if (window.location.pathname !== "/login") {
    window.location.pathname = "/login";
  }
};

export const Login = (credential) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADER_AUTH, payload: {} });
      const response = await auth.post(
        "https://live.avertisystems.com/api/v1/login",
        { ...credential },
      );
      window.localStorage.setItem("AUTH_TOKEN", response.data.accessToken);
      const data = response.data.accessToken;
      console.log(data, "data------------------");
      window.localStorage.setItem("isAuthenticated", true);
      window.localStorage.setItem(
        "x_monite_token",
        response.data.partner_token,
      );
      headers["Authorization"] = "Bearer " + response.data.accessToken;
      const responseMe = await auth.get(
        "https://live.avertisystems.com/api/v1/users/me",
        { headers },
      );
      window.localStorage.setItem(
        "userDetail",
        JSON.stringify(responseMe.data.data),
      );
      dispatch({ type: PROFILE_FETCH, payload: responseMe.data.data });
      dispatch({ type: SIGN_IN, payload: response.data });

      console.log("Login", Login);
      console.log("Login", Login);
    } catch (error) {
      localStorage.clear();
      dispatch(loginError());
    }
  };
};

export const ValidateOtp = (postData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADER_AUTH, payload: {} });
      const response = await auth.post("/otp/login", { ...postData });
      if (response.status === 200 && !response.data.error) {
        window.localStorage.setItem("AUTH_TOKEN", response.data.accessToken);
        window.localStorage.setItem("isAuthenticated", true);
        dispatch({ type: OTP, payload: response.data });
      } else {
        dispatch(otpError(response.data.error));
      }
    } catch (error) {
      dispatch(otpError("Opps something wrong!"));
      console.log(error);
    }
  };
};

export const GenerateOtp = (postData) => {
  return async (dispatch, getState) => {
    // console.log(getState)
    try {
      dispatch({ type: LOADER_AUTH, payload: {} });
      const response = await auth.post(
        "https://live.avertisystems.com/api/v1/generate-otp",
        { ...postData },
      );
      // console.log(response)
      if (response.status === 200) {
        dispatch({ type: OTP, payload: response.data });
      } else {
        // console.log(response.error)
        dispatch(otpError());
      }
    } catch (error) {
      dispatch(otpError());
      console.log(error);
    }
  };
};

export const Logout = () => {
  return async (dispatch, getState) => {
    try {
      window.localStorage.clear();
      window.localStorage.setItem("isAuthenticated", false);
      dispatch({ type: SIGN_OUT, payload: {} });
      goToLogin();
    } catch (error) {
      dispatch({ type: SIGN_OUT, payload: error });
    }
  };
};
export const showDataSky = (id) => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: LOADER_PROFILE, payload: {} });
      const response = await auth.get(
        "https://live.avertisystems.com/api/v1/getdetafrom?UserId=" + id,
        { headers },
      );
      dispatch({ type: PROFILE_UPDATE, payload: response.data });
    } catch (error) {}
  };
};
export const GetProfile = () => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: LOADER_PROFILE, payload: {} });
      const response = await auth.get(
        "https://live.avertisystems.com/api/v1/users/me",
        { headers },
      );
      window.localStorage.setItem(
        "userDetail",
        JSON.stringify(response.data.data),
      );
      dispatch({ type: PROFILE_FETCH, payload: response.data.data });
    } catch (error) {
      window.window.localStorage.removeItem("AUTH_TOKEN");
      dispatch({ type: PROFILE_ERROR, payload: error });
      goToLogin();
    }
  };
};

export const UpdateProfile = (FormsData) => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: LOADER_PROFILE, payload: {} });
      const response = await auth.put(
        `https://live.avertisystems.com/api/v1/users/${FormsData.id}`,
        FormsData,
        {
          headers,
        },
      );
      dispatch({ type: PROFILE_UPDATE, payload: response.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: PROFILE_ERROR, payload: error });
    }
  };
};
export const UpdateProfileSkyflow = (FormsData) => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: LOADER_PROFILE, payload: {} });
      const response = await auth.put(
        `https://live.avertisystems.com/api/v1/updateskyflow/${FormsData.id}`,
        FormsData,
        { headers },
      );
      dispatch({ type: PROFILE_UPDATE, payload: response.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: PROFILE_ERROR, payload: error });
    }
  };
};

export const UploadAvatar = (formData) => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: LOADER_PROFILE, payload: {} });
      const response = await auth.post(
        `https://live.avertisystems.com/api/v1/users/profile/upload`,
        formData,
        {
          headers,
        },
      );
      dispatch({ type: PROFILE_UPDATE, payload: response.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: PROFILE_ERROR, payload: error });
    }
  };
};

export const ChangePassword = (formData) => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: LOADER_PROFILE, payload: {} });
      const response = await auth.post(
        `https://live.avertisystems.com/api/v1/users/password/change`,
        formData,
        {
          headers,
        },
      );
      dispatch({ type: PASSWORD_CHANGE, payload: response.data });
    } catch (error) {
      dispatch({ type: PASSWORD_CHANGE_ERROR, payload: error });
    }
  };
};

export const galleryAction = (user) => {
  return async (dispatch) => {
    try {
      headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("AUTH_TOKEN");
      dispatch({ type: GET_GALLERY, payload: {} });
      const response = await auth.get(
        `https://live.avertisystems.com/api/v1/gallery/${user}`,
        { headers },
      );
      dispatch({ type: GET_GALLERY_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_GALLERY_ERROR, payload: error });
    }
  };
};

export const toggleTheme = (theme) => {
  return (dispatch) => {
    localStorage.setItem("darkTheme", theme);
    const isDarkMode = Boolean(theme) ? true : false;
    dispatch({ type: TOGGLE_THEME, payload: isDarkMode });
  };
};
